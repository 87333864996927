import { UnitApi } from 'src/common/types'

import ShortRating from '@components/star/ShortRating'

export default function UnitRating({ unit }: { unit: UnitApi }) {
  return (
    <>
      <ShortRating
        value={unit.buildingAverageRating}
        titleStyle="text-sm font-bold"
        countStyle="text-sm"
        count={unit.buildingReviewCount}
      />
    </>
  )
}
