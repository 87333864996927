import OIIcon from '@components/icons'
import { requireLogin } from '@redux/actions'

declare type Props = {
  isItemSaved: boolean
  onSave: (b: boolean) => void
  iconColor?: string
  customStyle?: string
  hasBackground?: boolean
  itemStyle?: string
}

export default function SaveItem({
  isItemSaved,
  onSave,
  customStyle,
  itemStyle,
  hasBackground = false,
}: Props) {
  const handleSave = (e) => {
    e.stopPropagation()
    requireLogin(() => {
      onSave(!isItemSaved)
    })
  }

  const SaveComp = isItemSaved ? (
    <OIIcon name="heart" className="fill-bluegray-400 text-bluegray-400" size="md" />
  ) : (
    <OIIcon name="heart" className="fill-transparent" size="md" />
  )

  const FullComp = hasBackground ? (
    <div
      className={itemStyle || 'inline-flex cursor-pointer items-center rounded-full bg-blue-25 p-2'}
      onClick={handleSave}
      title={isItemSaved ? 'Unsave' : 'Save to your profile'}
    >
      {SaveComp}
    </div>
  ) : (
    <div onClick={handleSave}>{SaveComp}</div>
  )
  return <div className={`z-10 inline-flex items-center ${customStyle}`}>{FullComp}</div>
}
