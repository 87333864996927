import round from 'lodash/round'
import React from 'react'

import { AiTwotoneStar } from '@react-icons/all-files/ai/AiTwotoneStar'

interface ShortRatingProps {
  value: number
  count?: number | string
  titleStyle?: string
  iconFamily?: string
  containerStyle?: string
  countStyle?: string
  starSize?: number
  noStar?: boolean
}
const ShortRating = ({
  value,
  count = '',
  titleStyle,
  containerStyle,
  countStyle,
  starSize = 18,
  noStar = false,
}: ShortRatingProps): JSX.Element => {
  const displayValue = round(value, 1)
  return (
    <div className={`inline-flex items-center ${containerStyle}`}>
      {!noStar && <AiTwotoneStar size={starSize} className="my-auto text-yellow-300" />}
      <div className="ml-1 text-left">
        <data className={titleStyle} value={value}>
          {displayValue}
        </data>
        <data className={`ml-1 font-thin text-gray-500 ${countStyle}`} value={count}>
          {count !== '' ? `(${count ?? 0})` : null}
        </data>
      </div>
    </div>
  )
}

export default ShortRating
