import { groupBy } from 'lodash'
import { getListingNeighborhoods, getTrendingNeighborhoods } from 'src/data/newListings'

import { newSearchSlice } from '@redux/reducers/newSearch'

export const getNewNeighborhood = () => async (dispatch) => {
  const neighborhoods = await getListingNeighborhoods()
  dispatch(newSearchSlice.actions.setNeighborhoods(neighborhoods))
  const groupedBrs = groupBy(neighborhoods, function (nbr) {
    return nbr.borough
  })
  delete groupedBrs?.null
  dispatch(newSearchSlice.actions.setGrpNeighborhoods(groupedBrs))
}

export const getTrendingNeighborhood = () => async (dispatch) => {
  const neighborhoods = await getTrendingNeighborhoods()
  dispatch(newSearchSlice.actions.setTrendingNeighborhoods(neighborhoods))
}
