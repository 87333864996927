import { round } from 'lodash'
import Link from 'next/link'
import { useCallback, useEffect, useState } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { MdCheck } from 'react-icons/md'
import { UnitApi } from 'src/common/types'
import { segment } from 'src/lib/Segments'

import { OIPill, OIPillSize, OIPillStatus, OIPillType } from '@components/UI/atoms'
import SaveItem from '@components/generic/SaveItem'
import OIIcon from '@components/icons'
import ListingInterestModal from '@components/newListing/listingInterestModal'
import UnitImages from '@components/newListing/unitImages'
import ShortRating from '@components/star/ShortRating'
import { saveUnit } from '@redux/actions'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { currency, splitAddress, titleCase } from '@utility/Utilities'

import UnitFeatures from './unitFeatures'

type Props = {
  unit: UnitApi
  showSaveIcon?: boolean
  customStyle?: string
  disableFullScreenImage?: boolean
  segmentBase?: string
  showSnapshotButton?: boolean
}
export default function ListingUnitCards({
  unit,
  showSaveIcon = true,
  customStyle = '',
  disableFullScreenImage = false,
  segmentBase = 'other',
  showSnapshotButton = false,
}: Props) {
  const { savedUnits, contactedUnits } = useAppSelector((store) => store.currentUser)
  const { addressLine1, addressLine2 } = splitAddress(titleCase(unit.fullAddress))
  const [hasUserContacted, setHasUserContacted] = useState(false)
  const [showListingInterestModal, setShowListingInterestModal] = useState(false)
  const isSavedUnit = useCallback(
    () => savedUnits && savedUnits.map((u) => u.id).includes(unit.id),
    [savedUnits, unit]
  )

  const dispatch = useThunkDispatch()

  useEffect(() => {
    setHasUserContacted(contactedUnits.find((x) => x.slug == unit.slug) ? true : false)
  }, [contactedUnits])

  return (
    <>
      <div
        className={`listingcard relative flex flex-col overflow-hidden rounded-lg border border-light-40 bg-white ${customStyle}`}
        onClick={() => {
          segment?.[segmentBase + '_unitcard']?.(unit.slug, unit.addressSlug)
          segmentBase == 'home' && segment?.home_verified_listings()
        }}
      >
        {unit.buildingReviewCount ? (
          <div className="absolute top-2 left-2 z-10 flex rounded-xl bg-white p-2 text-sm">
            <ShortRating
              value={unit.buildingAverageRating}
              count={unit.buildingReviewCount}
              titleStyle={'text-dark-700 text-sm'}
            />
            {round(unit?.buildingAverageRating, 1) >= 4.3 && unit.buildingReviewCount >= 10 && (
              <div className="flex items-center">
                <div className="ml-2 text-light-40">•</div>
                <div className="ml-2 text-sm text-yellow-500">Top Rated</div>
              </div>
            )}
          </div>
        ) : null}

        {showSaveIcon && (
          <div className="absolute right-2 top-2 z-10">
            <SaveItem
              isItemSaved={isSavedUnit()}
              onSave={(s) => {
                dispatch(saveUnit(unit, s))
                isSavedUnit()
                  ? segment?.[segmentBase + '_unitcard_unsave']?.(unit.slug, unit.addressSlug)
                  : segment?.[segmentBase + '_unitcard_save']?.(unit.slug, unit.addressSlug)
              }}
              hasBackground={true}
            />
          </div>
        )}
        <UnitImages
          unit={unit}
          disableFullScreenImage={disableFullScreenImage}
          segmentBase={segmentBase}
          onImageClick={() => {
            disableFullScreenImage &&
              window.open(`/unit/nyc/${unit.slug}/${unit.addressSlug}`, '_blank')
          }}
        />
        <Link href={`/unit/nyc/${unit.slug}/${unit.addressSlug}`}>
          <a rel="noreferrer" target="_blank" className="relative w-full cursor-pointer py-2">
            <div className="absolute right-2 top-4 text-right">
              {!unit.activeListing ? (
                <OIPill
                  size={OIPillSize.LARGE}
                  type={OIPillType.PRIMARY}
                  label="Closed"
                  status={OIPillStatus.ERROR}
                />
              ) : (
                <>
                  {unit.isBuildingVerified && (
                    <div className="mb-2">
                      <OIPill
                        type={OIPillType.PRIMARY_DARK}
                        style={`!py-2 !font-semibold !border !border-blue-25`}
                        label="Verified"
                      />
                    </div>
                  )}
                </>
              )}
              {unit.activeListing?.isFutureListing && (
                <div>
                  <OIPill
                    type={OIPillType.SECONDARY}
                    style={`!py-2 !font-semibold !border !border-bluegray-75 text-blue-200`}
                    label="Future"
                  />
                </div>
              )}
            </div>
            <div className="w-full px-4">
              {unit?.neighborhood && (
                <div className="mt-1 text-left text-sm font-semibold text-mid-400">
                  {unit.neighborhood.name}
                </div>
              )}
              <div className="my-1 flex w-full flex-row items-center justify-between">
                <div className="w-3/4 truncate">
                  <h2 className="flex flex-grow flex-col justify-start pt-1 text-left text-base font-semibold not-italic sm:justify-center">
                    <span className="truncate">{addressLine1}</span>
                    <span className="truncate">{addressLine2}</span>
                  </h2>
                </div>
              </div>
            </div>
            {unit.activeListing && (
              <div className="relative">
                <div>
                  <div className="w-full space-y-3 px-4 pt-1 pb-2">
                    {unit.activeListing?.rent ? (
                      <div className="flex items-center space-x-3">
                        <div className=" font-semibold text-dark-900">
                          {currency(unit.activeListing.rent)}
                        </div>
                        {unit.activeListing.isPrequalified && (
                          <div className="flex items-center space-x-1 text-xs font-semibold text-green-400">
                            <OIIcon name="checkCircle" className="text-green-400" size="md" />
                            <span>Pre-qualified</span>
                          </div>
                        )}
                      </div>
                    ) : null}
                    <UnitFeatures unit={unit} showBuildingRating={false} />
                  </div>
                </div>
              </div>
            )}
          </a>
        </Link>
        {showSnapshotButton && (
          <button
            onClick={() => {
              segment?.[segmentBase + '_listing_contactagent']?.()
              setShowListingInterestModal(true)
            }}
            disabled={hasUserContacted}
            className={`flex w-full items-center justify-center space-x-2 border-t border-light-40 py-4 ${
              hasUserContacted ? 'text-mid-300' : 'text-primary'
            } focus:outline-none`}
          >
            <span>{hasUserContacted ? 'Contacted' : 'Contact agent'}</span>
            {hasUserContacted ? (
              <MdCheck size={22} className="mb-1" />
            ) : (
              <FiChevronRight size={24} />
            )}
          </button>
        )}
      </div>
      {showListingInterestModal && (
        <ListingInterestModal
          listing={unit}
          hasUserContacted={hasUserContacted}
          onClose={() => setShowListingInterestModal(false)}
          segmentBase={segmentBase}
        />
      )}
    </>
  )
}
