import React, { useEffect } from 'react'
import { AiOutlineCloseCircle, AiOutlineExclamationCircle } from 'react-icons/ai'

export interface ToastProps {
  id: string
  destroy: () => void
  title: string
  message: string
  duration?: number
  color?: string
  type?: string
}

const Toast: React.FC<ToastProps> = (props) => {
  const { destroy, message, title, duration = 4000, color = '#FF991F', type = null } = props

  useEffect(() => {
    if (!duration) return

    const timer = setTimeout(() => {
      destroy()
    }, duration)

    return () => clearTimeout(timer)
  }, [destroy, duration])

  return (
    <div>
      {/* Todo - Dynamic color prop to border */}
      <div
        className={`absolute left-1/2 bottom-0 left-0 right-0 w-full max-w-[90%] -translate-x-1/2 space-y-1 rounded-lg border 
        border-l-8 border-light-40 ${
          type !== 'error' ? 'border-l-yellow-400' : 'border-l-red-400'
        } bg-white py-2 pl-4 pr-6 text-dark-900 shadow-xl sm:max-w-3/4 sm:max-w-md sm:py-4`}
      >
        <div className="flex items-center font-semibold sm:text-lg">
          {type == 'error' ? (
            <AiOutlineCloseCircle color={color} className="mr-2" />
          ) : (
            <AiOutlineExclamationCircle color={color} className="mr-2" />
          )}
          {title}
        </div>
        <div className="pl-7 text-sm text-dark-700">{message}</div>
      </div>
    </div>
  )
}

export default Toast
