import Image from 'next/image'
import { useCallback, useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { segment } from 'src/lib/Segments'

import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack'
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward'
import { IoIosClose } from '@react-icons/all-files/io/IoIosClose'

interface Props {
  images: string[]
  style?: string
  selectedItem?: number
  fullPage?: boolean
  onClose?: () => void
  onImageClick?: () => void
  customImageHeight?: string
  fit?: 'cover' | 'contain'
  browsable?: boolean
  disableFullScreenImage?: boolean
  segmentBase?: string
}

// const CustomCarouselStatus = ({ label }) => {
//   return (
//     <div className="absolute left-2 bottom-2 flex flex-row items-center space-x-2 rounded-full bg-black bg-opacity-60 p-2 text-white">
//       <HiOutlinePhotograph size={16} />
//       <span className="text-xxs">{label}</span>
//     </div>
//   )
// }

const CustomCarouselStatusDots = ({ index, length }) => {
  return (
    <div className="absolute left-1/2 bottom-2 flex -translate-x-1/2 flex-row items-center space-x-2 rounded-full p-2 text-white">
      {[...Array(length)].slice(0, 4).map((x, i) => {
        return (
          <div
            key={i}
            className={`h-1.5 w-1.5 rounded-full bg-white/[0.7] ${
              index === i && '!h-2 !w-2 !bg-white'
            }`}
          ></div>
        )
      })}
    </div>
  )
}

const CustomRightArrow = ({ clickHandler, hasNext }) => {
  if (!hasNext) {
    return null
  }
  return (
    <button
      className="absolute inset-y-1/2 right-1 z-10 h-8 w-8 rounded-full bg-white bg-opacity-50 p-2 shadow-md focus:outline-none"
      onClick={clickHandler}
    >
      <IoIosArrowForward />
    </button>
  )
}

const CustomLeftArrow = ({ clickHandler, hasPrev }) => {
  if (!hasPrev) {
    return null
  }
  return (
    <button
      className="absolute inset-y-1/2 left-1 z-10 h-8 w-8 rounded-full bg-white bg-opacity-50 p-2 shadow-md focus:outline-none"
      onClick={clickHandler}
    >
      <IoIosArrowBack />
    </button>
  )
}
//TODO: change this component to a full page carousel only
export default function ImageCarousel({
  images,
  style = '',
  selectedItem = 0,
  fullPage = false,
  onClose = null,
  onImageClick = null,
  customImageHeight = '',
  fit = 'contain',
  browsable = true,
  disableFullScreenImage = false,
  segmentBase = 'other',
}: Props) {
  const [currentIndex, setCurrentIndex] = useState(selectedItem)
  const [showFullPage, setShowFullPage] = useState(fullPage)
  const containerStyle = showFullPage
    ? 'fixed bg-black bg-opacity-80 top-0 left-0 z-[60] w-full h-screen'
    : style || 'flex w-full overflow-hidden relative h-72'

  const imageHeight = customImageHeight || 'h-72'

  const handleKeyPress = (e) => {
    if (showFullPage && e.keyCode == 27) {
      segment?.[segmentBase + '_unitcard_imageclose']?.()
      setShowFullPage(false)
    }
  }

  const [randomNumber, setRandomNumber] = useState(null)

  useEffect(() => {
    if (!randomNumber) {
      const randomNum = Math.floor(Math.random() * 5) + 1
      setRandomNumber(randomNum)
    }
  }, [randomNumber])

  const memoizedRandomImage = useCallback(() => randomNumber, [randomNumber])

  return (
    <div className={containerStyle} onKeyDown={handleKeyPress} tabIndex={0}>
      {showFullPage && (
        <div className="fixed z-50 flex w-full cursor-pointer justify-end font-extrabold text-white">
          <IoIosClose
            onClick={() => {
              segment?.[segmentBase + '_unitcard_imageclose']?.()
              if (onClose === null) {
                setShowFullPage(false)
              } else {
                onClose()
              }
            }}
            size={40}
          />
        </div>
      )}
      <div
        className={`flex w-full items-center justify-center ${
          showFullPage ? 'h-screen' : 'h-full'
        }`}
        onKeyDown={handleKeyPress}
      >
        {images.length === 0 ? (
          <Image
            src={`/unit_placeholder${memoizedRandomImage()}.png`}
            alt={'placenyc'}
            layout="fill"
            objectFit={fit}
            className="max-w-screen max-h-96"
            placeholder="blur"
            blurDataURL="/building-card-placeholder.png"
            unoptimized
          />
        ) : (
          <>
            <Carousel
              preventMovementUntilSwipeScrollTolerance={true}
              dynamicHeight={false}
              autoPlay={false}
              centerMode={true}
              centerSlidePercentage={100}
              className="flex w-full items-center justify-center"
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              selectedItem={selectedItem}
              onChange={(index) => setCurrentIndex(index)}
              showArrows={browsable}
              renderArrowPrev={(clickHandler, hasPrev) => (
                <CustomLeftArrow clickHandler={clickHandler} hasPrev={hasPrev} />
              )}
              renderArrowNext={(clickHandler, hasNext) => (
                <CustomRightArrow clickHandler={clickHandler} hasNext={hasNext} />
              )}
            >
              {(browsable ? images : images?.slice(0, 1))?.map((image, ind) => (
                <div
                  className={`relative flex w-full cursor-pointer items-center justify-center ${
                    showFullPage ? 'h-screen' : imageHeight
                  }`}
                  key={`image-${ind}`}
                  onClick={() => {
                    segment?.[segmentBase + '_unitcard_imageopen']?.()
                    setShowFullPage(!disableFullScreenImage)
                    onImageClick && onImageClick()
                  }}
                >
                  {showFullPage ? (
                    <div className="max-w-screen relative h-screen w-screen md:max-w-screen-md">
                      <Image
                        src={image ?? `/unit_placeholder${memoizedRandomImage()}.png`}
                        alt={image}
                        layout="fill"
                        objectFit="contain"
                        placeholder="blur"
                        blurDataURL="/building-card-placeholder.png"
                        unoptimized
                      />
                    </div>
                  ) : (
                    <Image
                      src={image ?? `/unit_placeholder${memoizedRandomImage()}.png`}
                      alt={image}
                      layout="fill"
                      objectFit={fit}
                      className="max-w-screen max-h-96"
                      placeholder="blur"
                      blurDataURL="/building-card-placeholder.png"
                      unoptimized
                    />
                  )}
                </div>
              ))}
            </Carousel>
            {images && images.length > 1 && browsable ? (
              <CustomCarouselStatusDots index={currentIndex} length={images.length} />
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}
