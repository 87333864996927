import { UnitApi } from 'src/common/types'

import { formatDateReport, pluralizeString } from '@utility/Utilities'

import UnitRating from './unitRating'

export default function UnitFeatures({
  unit,
  showBuildingRating,
}: {
  unit: UnitApi
  showBuildingRating: boolean
}) {
  const { numBedrooms, numBathrooms, activeListing } = unit

  return (
    <div className="flex w-full flex-wrap items-center gap-x-2 text-sm text-dark-700">
      {(numBedrooms ?? 0) > 0 ? (
        <>
          <span>{pluralizeString('bed', numBedrooms)}</span>
          <span className="text-light-40">&#x2022;</span>
        </>
      ) : (
        <>
          <span>Studio</span>
          <span className="text-light-40">&#x2022;</span>
        </>
      )}
      {(numBathrooms ?? 0) > 0 ? (
        <>
          <span>{pluralizeString('bath', numBathrooms)}</span>
          {activeListing && <span className="text-light-40">&#x2022;</span>}
        </>
      ) : null}
      {activeListing.isFutureListing ? (
        <span>Est. {formatDateReport(new Date(activeListing.availableDate), 'MMM YYYY')}</span>
      ) : (
        <span>
          Avail.{' '}
          {new Date(activeListing.availableDate) > new Date()
            ? formatDateReport(new Date(activeListing.availableDate), 'MMM DD, YYYY')
            : 'Immediately'}
        </span>
      )}
      {showBuildingRating && (
        <div className="ml-auto">
          <UnitRating unit={unit} />
        </div>
      )}
    </div>
  )
}
