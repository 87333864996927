export const RequiredDocumentsList = [
  {
    id: 'idCopyDoc',
    title: `Copy of ID`,
    desc: `Valid photo ID includes a driver's license or passport`,
    eventFunction: 'applicationprofile_upload_id',
    label: 'id',
  },
  {
    id: 'payStubsDoc',
    title: `3 most recent paystubs (or other sources of income)`,
    desc: `Must be full paystubs showing deductions, tax, etc. <br/>Screenshots of bank deposits will not be accepted.`,
    eventFunction: 'applicationprofile_upload_paystubs',
    label: 'pay_stubs',
  },
  {
    id: 'bankStatementsDoc',
    title: '3 most recent bank statements',
    desc: `Full PDF statements. NOT screenshots.`,
    eventFunction: 'applicationprofile_upload_bank_statement',
    label: 'bank_statements',
  },
]

export const SupportingDocumentsList = [
  {
    id: 'taxReturnDoc',
    title: `Most recent tax return (if applicable)`,
    desc: `Please upload the first two pages of a 1040 form.`,
    eventFunction: 'applicationprofile_upload_tax_return',
    label: 'tax_returns',
  },
  {
    id: 'rent_payment_history',
    title: '6-month rental payment history proof (if applicable)',
    desc: `For example: voided checks, venmo screenshots.`,
    eventFunction: 'applicationprofile_upload_payment_history',
    label: 'rent_payment_history',
  },
  // {
  //   id: 'landlordReferencesDoc',
  //   desc: `Landlord references`,
  //   eventFunction: 'applicationprofile_upload_lanlord_references',
  // },
  // {
  //   id: 'investmentsDoc',
  //   desc: `Investment accounts`,
  //   eventFunction: 'applicationprofile_upload_investment_accounts',
  // },
  {
    id: 'otherSupportingDoc',
    title: `Other documents (if applicable)`,
    desc: `For example: lanlord references and investment accounts.`,
    eventFunction: 'applicationprofile_upload_other_documents',
    label: 'other',
  },
]

export const CREDIT_SCORE_RANGES = [
  { value: '300-599', label: '300-599' },
  { value: '600-649', label: '600-649' },
  { value: '650-699', label: '650-699' },
  { value: '700-749', label: '700-749' },
  { value: '750-850', label: '750-850' },
  { value: 'no_us_credit_score', label: "I don't have US credit." },
]

export const PAYMENT_DAYS = [
  { value: 1, label: '1st of each month' },
  { value: 2, label: '2nd of each month' },
  { value: 3, label: '3rd of each month' },
  { value: 4, label: '4th of each month' },
  { value: 5, label: '5th of each month' },
]

export const MARITAL_STATUS = [
  { value: 'single', label: 'Single' },
  { value: 'separated', label: 'Separated' },
  { value: 'married', label: 'Married' },
  { value: 'divorced', label: 'Divorced' },
  { value: 'widow', label: 'Widow' },
  { value: 'other', label: 'Other' },
]
