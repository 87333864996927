import React from 'react'

import { OIButton } from '@components/UI/atoms'
import OIIcon from '@components/icons'

function ContactAgentSuccessModal({ isVisible = false, onClose }) {
  if (!isVisible) {
    return
  } else {
    return (
      <div className="fixed top-0 bottom-0 left-0 z-[100] !m-0 h-screen w-full bg-black bg-opacity-80 text-black">
        <div
          className="slide-up mx-auto my-20 h-auto w-fit overflow-y-auto rounded-2xl  bg-white"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex h-full flex-col items-center space-y-5 overflow-auto p-6 ">
            <OIIcon name={'checkVerified'} size="xl" className="text-green-400" />
            <p className="text-base font-semibold text-dark-900">Message sent!</p>
            <p className="text-base text-dark-700">We've sent your inquiry to the agent.</p>
            <OIButton
              label="Done"
              onClick={() => {
                onClose && onClose()
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ContactAgentSuccessModal
