import React from 'react'

type SeparatorProps = {
  customStyle?: string
}
const Separator = ({ customStyle }: SeparatorProps): JSX.Element => {
  return <div className={`my-4 h-px bg-gray-200 ${customStyle ? customStyle : ''}`} />
}

export default Separator
