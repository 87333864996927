import React, { useEffect, useState } from 'react'
import { UnitApi } from 'src/common/types'
import { submitNewInterest } from 'src/data/newUserProfile'
import { segment } from 'src/lib/Segments'
import isMobilePhone from 'validator/lib/isMobilePhone'

import Separator from '@components/Separator'
import { OIButton, OIButtonSize, OIInput } from '@components/UI/atoms'
import { OIModal } from '@components/UI/organisms'
import { CREDIT_SCORE_RANGES } from '@components/applicationProfile/Common/Constants'
import { toast } from '@components/errors/ToastManager'
import CustomDatePicker from '@components/generic/CustomDatePicker'
import CustomSelect from '@components/generic/CustomSelect'
import MultiSelectionRadioButton from '@components/review/MultiSelectionRadioButton'
import RadioButton from '@components/review/RadioButton'
import { ListingInterest } from '@localtypes/Store'
import { editNewProfile, requireLogin, setListingInterestBody } from '@redux/actions'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { newSearchSlice } from '@redux/reducers/newSearch'
import { userSlice } from '@redux/reducers/user'
import {
  formatIncome,
  formatPhoneNumber,
  formatTenancyDates,
  unformatIncome,
} from '@utility/Utilities'

import ContactAgentSuccessModal from './contactAgentSuccessModal'

export interface ListingInterestModalProps {
  listing: UnitApi
  hasUserContacted: boolean | null
  onClose: () => void
  segmentBase?: string
}

const ListingInterestModal = ({
  listing,
  onClose,
  segmentBase = 'other',
}: ListingInterestModalProps): JSX.Element => {
  const { token, profile, loadingData, contactedUnits, isLoggedIn, triggerConsent } =
    useAppSelector((state) => state.currentUser)
  const { listingInterestBody } = useAppSelector((state) => state.currentListing)
  const { profileFilter } = useAppSelector((state) => state.newSearch)
  const dispatch = useThunkDispatch()

  const [showInvalidInputs, setShowInvalidInputs] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [reSubmit, setReSubmit] = useState(false)
  const [consent, setConsent] = useState(true)
  const [isTriggered, setIsTriggered] = useState(false)

  const [interest, setInterest] = useState<ListingInterest>({
    phone: profile?.phone ?? '',
    message: listingInterestBody?.message,
    householdIncome: profileFilter?.householdIncome ?? 0,
    numOccupants: listingInterestBody?.numOccupants ?? null,
    creditScoreRange: profileFilter.creditScoreRange ?? null,
    hasPets: listingInterestBody?.hasPets ?? null,
    petType: listingInterestBody.petType ?? [],
    moveInDate: listingInterestBody?.moveInDate ?? null,
  })

  const isActionDisabled =
    !interest.numOccupants ||
    !interest.creditScoreRange ||
    !interest.householdIncome ||
    !interest.message ||
    !interest.phone ||
    !interest?.moveInDate ||
    (phoneError && !isMobilePhone(interest?.phone, 'en-US'))

  useEffect(() => {
    return () => {
      dispatch(newSearchSlice.actions.setStopLoading(false))
    }
  }, [])

  useEffect(() => {
    if (!loadingData.contactedUnits && reSubmit) {
      setReSubmit(false)
      handleSubmit()
    }
  }, [loadingData.contactedUnits, reSubmit])

  const handleSubmit = async () => {
    if (interest.phone !== profile?.phone) {
      dispatch(userSlice.actions.setNewInterestPhone(interest?.phone))
    }
    if (isActionDisabled) {
      toast.show({
        title: 'Complete required fields to continue',
        message: 'See fields marked in red.',
      })
      setShowInvalidInputs(true)
      return
    }
    dispatch(newSearchSlice.actions.setStopLoading(true))
    if (!isTriggered) {
      dispatch(userSlice.actions.setConsentEnabled(consent))
    }
    if (token && isLoggedIn && consent && !triggerConsent && !isTriggered) {
      setIsTriggered(true)
      if (interest.phone !== profile?.phone) {
        await dispatch(editNewProfile(token, { phone: interest?.phone }))
        dispatch(userSlice.actions.setTriggerConsent(true))
      } else if (!profile.isPhoneVerified) {
        dispatch(userSlice.actions.setTriggerConsent(true))
      }
    }
    requireLogin(
      async () => {
        if (loadingData.contactedUnits) {
          setIsTriggered(true)
          setReSubmit(true)
          return
        }
        try {
          if (contactedUnits?.find((x) => x.slug == listing.slug)) {
            setIsSubmitted(true)
            return
          }
          setIsSubmitting(true)
          await submitNewInterest(token, interest, listing.slug)
          dispatch(setListingInterestBody(interest))
          dispatch(userSlice.actions.addContactedUnits(listing))
          dispatch(
            newSearchSlice.actions.setProfileFilter({
              householdIncome: interest?.householdIncome,
              creditScoreRange: interest?.creditScoreRange,
            })
          )
          await dispatch(editNewProfile(token, { phone: interest?.phone }))
          setIsSubmitted(true)
          const listingType = listing.activeListing?.isFutureListing
            ? 'Future Listing'
            : 'Active Listing'
          segment?.[segmentBase + '_contact_agent_submit_interest']?.()
          segment.global_contact_agent_submit_interest?.(
            segmentBase,
            listing.id,
            profile.email,
            listing?.isBuildingVerified,
            listing.activeListing?.rent,
            listing?.neighborhood?.name,
            listing?.numBedrooms,
            listing?.numBathrooms,
            listingType
          )
        } catch (error) {
          toast.show({
            title: 'Something went wrong.',
            message: '',
          })
        } finally {
          setIsSubmitting(false)
          dispatch(userSlice.actions.setNewInterestPhone(null))
        }
      },
      null,
      '',
      'default',
      null,
      interest?.phone
    )
  }

  const getLabel = (noOfOccupants: number) => {
    switch (noOfOccupants) {
      case 1:
        return '1 person'
      case 2:
        return '2 people'
      case 3:
        return '3 people or above'
      default:
        return ''
    }
  }

  return (
    <OIModal
      customHeight={false}
      customModalStyle="!max-w-xl !min-h-[400px] h-full"
      onClose={onClose}
      title="Contact agent"
    >
      <div className="h-full space-y-5 overflow-auto p-6 pb-12">
        <div className="my-4 rounded-xl bg-light-10 py-4 px-2 sm:px-4">
          <p className="text-xs font-semibold uppercase text-mid-400">Household Info</p>
          <Separator customStyle="-mx-2 sm:-mx-4 my-2" />
          <div className="my-4 grid-cols-2 gap-x-4 gap-y-2 space-y-4 sm:grid sm:space-y-0">
            <div className="application-input required-before my-0">
              <label htmlFor="date">Move-in date</label>
              <CustomDatePicker
                inputStyle={`${
                  !interest?.moveInDate && showInvalidInputs ? '!border-red-400' : ''
                }`}
                selectedDate={interest?.moveInDate}
                onChange={(date) => {
                  segment?.unit_contact_agent_movein()
                  setInterest({ ...interest, moveInDate: formatTenancyDates(date) })
                }}
                minDate={new Date()}
              />
            </div>

            <div className="application-input required-before">
              <label htmlFor="date">Number of occupants</label>
              <CustomSelect
                isInvalid={showInvalidInputs && !interest?.numOccupants}
                placeholder={'Select a number'}
                value={
                  interest?.numOccupants
                    ? { value: interest?.numOccupants, label: getLabel(interest?.numOccupants) }
                    : null
                }
                options={[
                  { value: 1, label: getLabel(1) },
                  { value: 2, label: getLabel(2) },
                  { value: 3, label: getLabel(3) },
                ]}
                isSearchable={false}
                onChange={(item: any) => {
                  segment?.unit_contact_agent_occupant_number()
                  setInterest({ ...interest, numOccupants: item.value })
                }}
              />
            </div>
          </div>
          <div className="-mb-4">
            <div className="application-input md:flex-row md:items-center md:space-x-4">
              <label>Any pets?</label>
              <RadioButton
                selectedValue={interest?.hasPets}
                options={{ Yes: true, No: false }}
                onSelect={async (sel) => {
                  segment?.unit_contact_agent_pets()
                  setInterest({ ...interest, hasPets: sel })
                }}
              />
            </div>
            {interest?.hasPets ? (
              <div className="application-input">
                <label>What pets do you have?</label>
                <MultiSelectionRadioButton
                  options={{ Cat: 'cat', Dog: 'dog', 'Other exotic pets': 'other' }}
                  selectedValues={interest?.petType}
                  onSelect={(val) => {
                    segment?.unit_contact_agent_pets_type()
                    setInterest({ ...interest, petType: val })
                  }}
                  style="mt-2"
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="my-4 rounded-xl bg-light-10 py-4 px-2 sm:px-4">
          <p className="text-xs font-semibold uppercase text-mid-400">Financial Info</p>
          <Separator customStyle="-mx-2 sm:-mx-4 my-2" />
          <div className="my-4 grid-cols-2 gap-x-4 gap-y-2 space-y-4 sm:grid sm:space-y-0">
            <div className="application-input required-before my-0">
              <label>Annual income</label>
              <OIInput
                invalid={showInvalidInputs && !interest?.householdIncome}
                className="!text-dark-900"
                placeholder="Gross annual income"
                type="text"
                value={interest.householdIncome ? formatIncome(interest?.householdIncome) : ''}
                onChange={(e) => {
                  setInterest({ ...interest, householdIncome: unformatIncome(e.target.value) })
                }}
                onFocus={() => {
                  segment?.unit_contact_agent_income()
                }}
              />
            </div>
            <div className="application-input required-before my-0 mt-4 !-mb-4">
              <label htmlFor="date">Credit score range</label>
              <CustomSelect
                isInvalid={showInvalidInputs && !interest?.creditScoreRange}
                placeholder="Select a credit range"
                options={CREDIT_SCORE_RANGES}
                value={
                  !interest?.creditScoreRange
                    ? null
                    : interest?.creditScoreRange !== 'no_us_credit_score'
                    ? { value: interest?.creditScoreRange, label: interest?.creditScoreRange }
                    : { value: interest?.creditScoreRange, label: "I don't have US credit." }
                }
                isSearchable={false}
                onChange={(item: any) => {
                  segment?.unit_contact_agent_credit_score()
                  setInterest({ ...interest, creditScoreRange: item.value })
                }}
              />
            </div>
          </div>
        </div>
        <div className="my-4 rounded-xl bg-light-10 py-4 px-2 sm:px-4">
          <p className="text-xs font-semibold uppercase text-mid-400">Contact</p>
          <Separator customStyle="-mx-2 sm:-mx-4 my-2" />

          <div className="application-input required-before my-0 mt-4">
            <label>Phone</label>
            <OIInput
              type={'tel'}
              placeholder="(+1)"
              className="!text-dark-900"
              value={interest?.phone}
              invalid={interest?.phone && !isMobilePhone(interest?.phone, 'en-US')}
              onChange={(e) => {
                setInterest({ ...interest, phone: formatPhoneNumber(e.target.value) })
              }}
              onBlur={() => {
                if (interest?.phone && !isMobilePhone(interest?.phone, 'en-US')) {
                  setPhoneError(true)
                } else {
                  // setPhoneError(false)
                }
              }}
              onFocus={() => {
                segment?.unit_contact_agent_phone()
              }}
            />
            {phoneError && !isMobilePhone(interest?.phone, 'en-US') && (
              <div className="-mt-3 text-xs text-red-400">Please enter a valid phone number</div>
            )}
          </div>

          <div className="application-input required-before mb-1">
            <label htmlFor="name" className="!text-dark-600">
              Message
            </label>
            <textarea
              onChange={(e) => setInterest({ ...interest, message: e.target.value })}
              value={interest?.message}
              placeholder="What do you want to tell the agent?"
              rows={3}
              className={`!text-dark-900 ${
                showInvalidInputs && !interest?.message ? '!border-red-400' : ''
              }`}
              onFocus={() => {
                segment?.unit_contact_agent_message()
              }}
            />
          </div>
          {(!profile?.isPhoneVerified || profile?.phone !== interest?.phone) && (
            <div className="pt-3">
              <RadioButton
                selectedValue={consent}
                customStyle={'!items-start'}
                options={{
                  'Sign me up for text alerts! Get the latest listings and updates from openigloo. Reply STOP to unsubscribe.':
                    true,
                }}
                onSelect={async () => {
                  if (consent) {
                    segment?.unit_contact_agent_sms_consent_uncheck()
                    setConsent(false)
                  } else {
                    segment?.unit_contact_agent_sms_consent_check()
                    setConsent(true)
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="flex items-center justify-center">
          <OIButton
            onClick={handleSubmit}
            label="Submit interest"
            style={`!text-sm w-48 mb-10 sm:mb-0`}
            size={OIButtonSize.LARGE}
            isLoading={isSubmitting}
            disabled={isActionDisabled}
          />
        </div>
      </div>
      {isSubmitted && (
        <ContactAgentSuccessModal
          isVisible={isSubmitted}
          onClose={() => {
            setIsSubmitted(false)
            onClose()
          }}
        />
      )}
    </OIModal>
  )
}

export default ListingInterestModal
