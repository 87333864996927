import { useEffect, useState } from 'react'
import Select, { Props } from 'react-select'

interface CustomSelectProps extends Props {
  isInvalid?: boolean
  customCss?: string
  inputCustomHeight?: string
}

const CustomSelect = (props: CustomSelectProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [menuPortalTarget, setMenuPortalTarget] = useState(null)

  useEffect(() => {
    // Only run on client-side
    setMenuPortalTarget(document.body)
  }, [])

  return (
    <Select
      className={props?.customCss ?? ''}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      menuPortalTarget={menuPortalTarget}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#EAEAFF',
          primary: '#6764A1',
        },
      })}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (base, state) => ({
          ...base,
          backgroundColor: 'transparent',
          borderColor: 'rgb(223 225 230)',
          borderRadius: '1000px',
          ...(isMenuOpen
            ? {
                borderRadius: '20px',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
              }
            : {}),
          padding: '0 8px',
          height: props?.inputCustomHeight ?? '36px',
          display: 'flex',
          alignItems: 'center',
          outline: state.isFocused ? 'transparent' : 'white',
          background: 'white',
          ...(props.isInvalid
            ? {
                border: '1px solid #DE350B',
              }
            : {}),
        }),
        input: (base) => ({
          ...base,
          padding: 'auto 8px',
        }),
        menu: (base) => ({
          ...base,
          marginTop: '1px',
          padding: '8px 0',
          borderRadius: '0',
          borderBottomLeftRadius: '24px',
          borderBottomRightRadius: '24px',
          overflow: 'hidden',
          zIndex: 30,
        }),
      }}
      {...props}
    />
  )
}

export default CustomSelect
