import { range } from 'lodash'
import { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FiCalendar } from 'react-icons/fi'
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi'

import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack'
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward'
import { formatDate } from '@utility/Utilities'

interface CDPProps {
  minDate?: any
  selectedDate: any
  onChange: (date: any) => void
  disabled?: boolean
  placeholderText?: string
  maxDate?: any
  inputStyle?: string
}

const CustomDatePicker = ({
  minDate = null,
  maxDate = null,
  selectedDate,
  onChange,
  disabled,
  placeholderText = 'MM / DD / YYYY',
  inputStyle = '',
}: CDPProps) => {
  // @ts-expect-error unknown
  // eslint-disable-next-line react/display-name
  const CustomInput = forwardRef(function ({ value, onClick }, ref) {
    return (
      <div className={`relative`} onClick={onClick}>
        <div
          // @ts-expect-error ref not found
          ref={ref}
          placeholder={placeholderText}
          className={`flex h-9 w-full cursor-pointer items-center rounded-3xl border  py-1  px-4 text-left  ${
            disabled ? 'bg-light-20 text-dark-500 opacity-50' : 'bg-white'
          } ${value ? 'text-dark-900' : 'text-dark-900/60'} ${inputStyle}`}
        >
          {value ? value : placeholderText}
        </div>
        <FiCalendar className="absolute top-1/2 right-4 -translate-y-1/2 text-lg text-blue-50" />
      </div>
    )
  })

  const CustomYearPicker = ({ changeYear, currentYear, changeMonth }) => {
    let maxYear = new Date().getFullYear() + 100
    let minYear = new Date().getFullYear() - 100
    const minMonth = new Date(minDate).getMonth()
    const maxMonth = new Date(maxDate).getMonth()
    const selectedMonth = new Date(selectedDate).getMonth()
    if (maxDate) {
      maxYear = new Date(maxDate).getFullYear()
    }
    if (minDate) {
      minYear = new Date(minDate).getFullYear()
    }
    const years = range(minYear, maxYear + 1)

    return (
      <select
        value={currentYear}
        className="rounded-3xl py-0.5 text-sm"
        onChange={(e) => {
          changeYear(parseInt(e.target.value))
          if (parseInt(e.target.value) === minYear && selectedMonth < minMonth) {
            changeMonth(minMonth)
          } else if (parseInt(e.target.value) === maxYear && selectedMonth > maxMonth) {
            changeMonth(maxMonth)
          }
        }}
      >
        {years.map((year) => {
          return (
            <option value={year} key={year}>
              {year}
            </option>
          )
        })}
      </select>
    )
  }

  return (
    <div className="custom-date-picker">
      <DatePicker
        showPopperArrow={false}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        selected={selectedDate ? new Date(formatDate(selectedDate, 'MM/DD/YYYY')) : null}
        dateFormat="MMM dd, yyyy"
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3)}
        placeholderText={placeholderText}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          increaseYear,
          decreaseYear,
          changeYear,
          changeMonth,
        }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'white',
              padding: '5px 0',
            }}
          >
            <HiChevronDoubleLeft className="text-mid-400" size={16} onClick={decreaseYear} />
            <IoIosArrowBack className="text-mid-400" size={16} onClick={decreaseMonth} />
            <span className="text-base text-dark-900">{formatDate(date, 'MMMM')}</span>
            <CustomYearPicker
              changeMonth={changeMonth}
              currentYear={formatDate(date, 'YYYY')}
              changeYear={changeYear}
            />
            <IoIosArrowForward className="text-mid-400" size={16} onClick={increaseMonth} />
            <HiChevronDoubleRight className="text-mid-400" size={16} onClick={increaseYear} />
          </div>
        )}
        onChange={onChange}
        customInput={<CustomInput />}
      />
    </div>
  )
}

export default CustomDatePicker
