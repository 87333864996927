import { segment } from 'src/lib/Segments'

export default function MultiSelectionRadioButton({
  options,
  selectedValues = [],
  onSelect,
  style = '',
  eventName = null,
  segmentBase = 'other',
  customStyle = '',
  disabled = false,
  isHorizontal = true,
  isRounded = true,
}) {
  return (
    <div className={`flex ${isHorizontal ? 'flex-row flex-wrap' : 'flex-col'} ${style}`}>
      {Object.keys(options).map((key) => {
        const value = options[key]
        const isSelected = selectedValues.includes(value)
        const bg = isSelected
          ? 'bg-bluegray-200'
          : disabled
          ? 'bg-light-40'
          : 'bg-light-gray border border-light-50'

        return (
          <div
            className={`flex cursor-pointer flex-row items-center ${customStyle} ${
              disabled && 'cursor-not-allowed'
            } ${isHorizontal ? 'mr-4' : 'mb-4'}`}
            key={key}
            onClick={() => {
              if (disabled) return

              const newSelectedValues = isSelected
                ? selectedValues.filter((v) => v !== value) // Deselect if already selected
                : [...selectedValues, value] // Add to selection

              onSelect(newSelectedValues)

              eventName &&
                segment?.[segmentBase + '_multi_check_btn']?.(eventName, newSelectedValues)
            }}
          >
            <div
              className={`relative flex h-4 w-4 flex-shrink-0 items-center justify-center ${
                isRounded ? 'rounded-xl' : 'rounded'
              } ${bg}`}
            >
              <div
                className={`relative h-2 w-2  ${isRounded ? 'rounded-full' : ''} ${
                  isSelected ? 'bg-blue-25' : ''
                }`}
              ></div>
            </div>
            <span
              className={`ml-2 text-sm ${
                isSelected ? 'text-dark-800' : disabled ? 'text-light-40' : 'text-dark-800'
              }`}
            >
              {key}
            </span>
          </div>
        )
      })}
    </div>
  )
}
