import { UnitApi } from 'src/common/types'

import ImageCarousel from '@components/generic/ImageCarousel'

type UnitImagesProps = {
  disableFullScreenImage?: boolean
  unit: UnitApi
  segmentBase?: string
  onImageClick?: () => void
}

export default function UnitImages({
  unit,
  disableFullScreenImage = false,
  segmentBase = 'other',
  onImageClick = null,
}: UnitImagesProps) {
  const imageUrls = unit.images.reduce((acc, obj) => {
    const url = obj?.imageS || obj?.url
    if (url) {
      acc.push(url)
    }
    return acc
  }, [])

  return (
    <div className="w-full overflow-hidden">
      <ImageCarousel
        images={imageUrls}
        style="flex w-full relative h-60"
        fit="cover"
        browsable={true}
        segmentBase={segmentBase}
        disableFullScreenImage={disableFullScreenImage}
        onImageClick={onImageClick}
      />
    </div>
  )
}
